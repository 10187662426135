import Vue from "vue";
import VaImgPreviewer from "./va_img_previewer.vue";

let VaImgPreviewerInstance = null;

let init = () => {
  let previewer = Vue.extend(VaImgPreviewer);
  VaImgPreviewerInstance = new previewer({});
  VaImgPreviewerInstance.$mount();

  document.querySelector("#app").appendChild(VaImgPreviewerInstance.$el);
  VaImgPreviewerInstance.$el.style.zIndex = 9999;
};

let caller = (options) => {
  if (!VaImgPreviewerInstance) {
    init(options);
  }
  VaImgPreviewerInstance.open(options);
};

export default {
  install(vue) {
    vue.prototype.$imgPreviewer = caller;
  },
};
