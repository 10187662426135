const guard = (to, from, next) => {
  const zShopToken = localStorage.getItem("zShopToken");
  if (!zShopToken) {
    next("/signin");
  }
  next();
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", name: "all", redirect: "/signin" },
    {
      path: "/admin",
      name: "Admin",
      component: () => import("./views/admin.vue"),
      beforeEnter: (to, from, next) => {
        // other no admin account go to /admin page will redirect to 404
        const zShopToken = localStorage.getItem("zShopToken");
        const isAdmin = zShopToken ? JSON.parse(zShopToken).user_type === "VAMS_ADMIN_CONSUMER" : false;
        if (zShopToken) {
          if (!isAdmin) {
            next("/404");
          } else {
            next();
          }
        } else {
          next("/404");
        }
      },
    },
    {
      path: "/signin",
      name: "SignIn",
      component: () => import("./views/signin.vue"),
    },
    {
      path: "/home",
      name: "home",
      component: () => import("./views/product_home.vue"),
      props: true,
    },
    {
      path: "/general_faq",
      name: "general_faq",
      component: () => import("./views/general_faq.vue"),
    },
    {
      path: "/reset_password/:token",
      name: "ResetPassword",
      component: () => import("./views/reset_password.vue"),
    },
    {
      path: "/product/:product_id",
      name: "product_detail",
      component: () => import("./views/product_detail.vue"),
    },
    {
      path: "/cart",
      name: "shopping_cart",
      component: () => import("./views/shopping_cart.vue"),
    },
    {
      path: "/check_out",
      name: "check_out",
      component: () => import("./views/check_out.vue"),
    },
    {
      path: "/product_tracking",
      name: "product_tracking",
      component: () => import("./views/product_tracking.vue"),
    },
    {
      path: "/product_tracking_new",
      name: "product_tracking_new",
      component: () => import("./views/product_tracking_new.vue"),
    },
    {
      path: "/product_tracking_verification",
      name: "product_tracking_verification",
      component: () => import("./components/product_tracking_verification.vue"),
    },
    {
      path: "/account_detail",
      name: "account_detail",
      component: () => import("./views/account_detail.vue"),
      beforeEnter: guard,
    },
    {
      path: "/order_confirmation",
      name: "order_confirmation",
      component: () => import("./views/order_confirmation.vue"),
      props: true,
    },
    {
      path: "/orders_returns",
      name: "orders_returns",
      component: () => import("./views/orders_returns.vue"),
      beforeEnter: guard,
    },
    {
      path: "/message",
      name: "message",
      component: () => import("./views/message.vue"),
      beforeEnter: guard,
    },
    {
      path: "/subscription",
      name: "subscription",
      component: () => import("./views/subscription.vue"),
      beforeEnter: guard,
    },
    {
      path: "/subscription_one/sub_id=:sub_id",
      name: "subscription_one",
      component: () => import("./views/subscription_one.vue"),
      beforeEnter: guard,
      props: true,
    },
    {
      path: "/favorite_list",
      name: "favorite_list",
      component: () => import("./views/favorite_list.vue"),
      beforeEnter: guard,
    },
    {
      path: "/store/",
      name: "store",
      component: () => import("./views/store_link.vue"),
      beforeEnter: guard,
    },
    {
      path: "/webpage",
      component: () => import("./views/store_landing.vue"),
    },
    {
      path: "/store_entry_with_token/:token",
      name: "store_entry_with_token",
      component: () => import("./views/store_entry_with_token.vue"),
    },
    {
      path: "/store_list/:token",
      name: "store_list",
      component: () => import("./views/store_list.vue"),
      beforeEnter: guard,
    },
    {
      path: "/request_store/:store_id/:token",
      name: "request_store",
      component: () => import("./views/request_store.vue"),
      beforeEnter: guard,
    },
    {
      path: "/shipping_address_manage",
      name: "shipping_address_manage",
      component: () => import("./views/shipping_address_manage.vue"),
      beforeEnter: guard,
    },
    {
      path: "/payment_manage",
      name: "payment_manage",
      component: () => import("./views/payment_manage.vue"),
      beforeEnter: guard,
    },
    {
      path: "/preview/store/:store_id",
      name: "preview_home",
      component: () => import("./views/preview_home.vue"),
    },
    {
      path: "/preview/product/:product_id",
      name: "preview_product_detail",
      component: () => import("./views/preview_product_detail.vue"),
    },
    {
      path: "/preview/store/",
      name: "preview_home_no_id",
      component: () => import("./views/preview_home.vue"),
    },
    {
      path: "/recommendations",
      name: "recommendations",
      component: () => import("./views/recommendations.vue"),
      beforeEnter: guard,
    },
    {
      path: "/email_unsubscribe",
      name: "email_unsubscribe",
      component: () => import("./views/email_unsubscribe.vue"),
    },
    {
      path: "/external/verification_email_token/:token",
      name: "verification_email_token",
      component: () => import("./views/verification_email_token.vue"),
      beforeEnter: guard,
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./views/not_found.vue"),
    },
    {
      path: "*",
      name: "404_not_found",
      component: () => import("./views/not_found.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  // admin account cant go to other page.
  const zShopToken = localStorage.getItem("zShopToken");
  const isAdmin = zShopToken ? JSON.parse(zShopToken).user_type === "VAMS_ADMIN_CONSUMER" : false;
  if (to.path !== "/admin" && isAdmin) {
    next("/admin");
  } else {
    next();
  }
});

export default router;
