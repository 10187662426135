import config from "../js/config.js";

const util = {
	getStoreId() {

		if (location.hostname.split(".").length == 3) {
			if (localStorage.store_id != undefined) {
				return localStorage.store_id;
			} else if (localStorage.storeInfo) {
				return JSON.parse(localStorage.storeInfo).store_id;
			} else {
				// if(window.location.pathname == '/patient/home') {
				// 	location.reload();
				// }
				return -1;
			}

		} else if (location.hostname == "localhost") {
			// for localhost nologin mode testing 
			return 4;
			// if (localStorage.store_id != undefined) {
			// 	return localStorage.store_id;
			// } else {
			// 	return -1;
			// }
		} else {
			return -1;
		}

	},
	navToStore(item, token) {
		if (location.hostname == 'localhost') {
			window.location = location.protocol + "//" + location.hostname + ":" + location.port + config.subdomain + "/store_entry_with_token/" + token;
		} else {
			let arr = location.hostname.split(".");
			if (arr.length == 3) {
				arr[0] = item.sellerInfo.zshopUrl;
			} else if (arr.length == 2) {
				arr.unshift(item.sellerInfo.zshopUrl);
			} else {
				alert("domain error");
			}
			window.location = location.protocol + "//" + arr.join(".") + config.subdomain + "/store_entry_with_token/" + token;
		}
	},
	navToStoreSwitch(self) {
		if (location.hostname == 'localhost') {
			if (self.$route.params.token != undefined) {
				window.location.replace(location.protocol + "//" + location.hostname + ":" + location.port + config.subdomain + "/store_list/" + self.$route.params.token);
			} else if (localStorage.zShopToken != undefined) {
				window.location.replace(location.protocol + "//" + location.hostname + ":" + location.port + config.subdomain + "/store_list/" + JSON.parse(localStorage.zShopToken).access_token);
			} else {
				self.$router.push('/store_list');
				//self.$router.replace('/store_list/' + self.$route.params.token);
				// alert("token not found!");
			}
		} else {
			// This shift is used to log out somehow ???
			let arr = location.hostname.split(".");
			// if (arr.length == 3) {
			// 	arr.shift();
			// } else if (arr.length == 2) {
			// 	console.log("nothing to do");
			// } else {
			// 	alert("domain error");
			// }
			// console.log(self.$route.params.token);
			if (self.$route.params.token != undefined) {
				console.log("here??");
				window.location = location.protocol + "//" + arr.join(".") + config.subdomain + "/store_list/" + self.$route.params.token;
			} else if (localStorage.zShopToken != undefined) {
				window.location = location.protocol + "//" + arr.join(".") + config.subdomain + "/store_list/" + JSON.parse(localStorage.zShopToken).access_token;
			} else {
				self.$router.push('/store_list');
			}

		}
	},
	navToRequest(item, token) {
		if (location.hostname == 'localhost') {
			window.location = location.protocol + "//" + location.hostname + ":" + location.port + config.subdomain + "/request_store/" + item.sellerInfo.sellerId + "/" + token;
		} else {
			let arr = location.hostname.split(".");
			if (arr.length == 3) {
				arr[0] = item.sellerInfo.zshopUrl;
			} else if (arr.length == 2) {
				arr.unshift(item.sellerInfo.zshopUrl);
			} else {
				alert("domain error");
			}
			window.location = location.protocol + "//" + arr.join(".") + config.subdomain + "/request_store/" + item.sellerInfo.sellerId + "/" + token;
		}
	},
	navLogout(self) {
		// localStorage.removeItem('zShopToken');
		// if(location.hostname == 'localhost'){
		// 	window.location.replace(location.protocol + "//" + location.hostname + ":" + location.port + config.subdomain + "/signin/");
		// }else{
		// 	let arr = location.hostname.split(".");
		// 	if(arr.length == 3){
		// 		arr.shift();
		// 	}else if(arr.length == 2){
		// 		console.log("nothing to do");
		// 	}else{
		// 		alert("domain error");
		// 	}
		// 	window.location.replace(location.protocol + "//" + arr.join(".") + config.subdomain + "/signin/"); 
		// }
		localStorage.clear();
		if (location.hostname == 'localhost') {
			if (self.$route.path != '/signin') {
				self.$router.push("/");
			}

		} else {
			let arr = location.hostname.split(".");
			if (arr.length == 3) {
				arr.shift();
			} else if (arr.length == 2) {
				console.log("nothing to do");
			} else {
				alert("domain error");
			}
			window.location.replace(location.protocol + "//" + arr.join(".") + config.subdomain + "/signin/");
		}

		// window.history.pushState(null, null, document.URL);
		// window.addEventListener("popstate",function(e) { 
		// 	history.pushState(null, null, document.URL);
		// }, false);
	},

	///////////////// Production /////////////
	APIDomain: "https://api.wellproz.com",

	APISubDomain: "/wellproz_api",
	APISubDomainNoauth: "/wellproz_api",

	APIVAMS: "/vams_api_auth",
	APIVAMSNoauth: "/vams_api",

	StripAPIKey: "pk_live_51GzScAE4CqvF2Rijoug057udPNvV6sgQMReSJ4uj5V0d9QZAq0iHtj45XDNi2bhYPFOANY5jc3AVQ6hYVtqv5K0h008slpWoGb",

	///////////////// SG /////////////
	// APIDomain: "https://api.wellproz.com",

	// APISubDomain: "/wellproz_api_sg",
	// APISubDomainNoauth: "/wellproz_api_sg",

	// APIVAMS: "/vams_api_sg_auth",
	// APIVAMSNoauth: "/vams_api_sg",

	// StripAPIKey: "pk_test_51JNkudKYAskmC2pNab5zWeiksp0IY6MVakZNay54tia5z284sKNDU34Xmo6g6Ut9alcJwRg5k20lGVo60RH2VRRP00IL2q9EF3",
	

	///////////////// Development /////////////
	// APIDomain: "https://api.wellproz.com",

	// APISubDomain: "/wellproz_api_dev",
	// APISubDomainNoauth: "/wellproz_api_dev",

	// APIVAMS: "/vams_api_dev_auth",
	// APIVAMSNoauth: "/vams_api_dev",

	// StripAPIKey: "pk_test_51JNkudKYAskmC2pNab5zWeiksp0IY6MVakZNay54tia5z284sKNDU34Xmo6g6Ut9alcJwRg5k20lGVo60RH2VRRP00IL2q9EF3",

	///////// check /////////
	checkSystem() {
		var agent = navigator.userAgent.toLowerCase();
		var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
		if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
			return 'win';
		}
		if(isMac) {
			return 'mac';
		}
	}
}
export default util;