<template>
  <transition name="fade">
    <div class="pop-up" v-show="show">
      <div class="content">
        <img :src="imgSrc" alt="img-previewer" />
        <button @click="show = false">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="backdrop" @click="show = false" style="z-index: -1"></div>
    </div>
  </transition>
</template>
<script type="text/javascript">
export default {
  name: "VaImgPreviewer",
  data() {
    return {
      show: false,
      imgSrc: "",
    };
  },
  methods: {
    open({ imgSrc }) {
      this.show = true;
      this.imgSrc = imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
.pop-up {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    position: relative;
    width: 65vh;
    height: 65vh;
    background: white;
    border-radius: 4px;
    z-index: 20;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    button {
      position: absolute;
      top: -3rem;
      right: -1rem;
      background: none;
      border: none;
      cursor: pointer;
      i {
        font-size: 24px;
        color: rgb(87, 87, 87);
      }
    }
  }
}
.backdrop {
  cursor: zoom-out;
}
</style>
