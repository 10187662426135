import Vue from "vue";
import Vuex from "vuex";
import util from "../js/util.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: 0,
    themeColorPalette: {
      primaryColor: "#003458",
      secondaryColor: "#DE4E7C",
    },
    storeProfile: {},
    faqReadQuantity: 0,
    filterFixed: false,
    payable: false,
    displayFilter: false,
    global_loading: {
      show: false,
      case: "general",
      style: {
        message: "aaaa",
      },
    },
    subcategoryFilter: {},
    global_filter: {
      price: [0, 300],
      productCategories: [],
      productSubcategories: [],
      productName: "",
      sellerId: -1,
      onSale: null,
      featured: null,
      autoShip: null,
      popular: null,
      atHome: null,
      sortBy: null,
      sortDir: "descend",
      pageNum: 0,
      pageSize: 30,
      suspend: false,
      draft: false,
      expired: false,
      deleted: false,
    },
    profile_img: "https://res.cloudinary.com/deuwrurfs/image/upload/v1633543841/user_profile/hjnygcwytn6r6nbqq52h.png",
    currencyProperty: {
      showCurrencyConvert: false,
      defaultCurrencyType: "CAD",
      currencySymbol: "$",
      currencyAbbr: " USD",
      isInternational: false,

      providerRequired:false,
    },
    errorObject:{
      "First Name": {
        index:1,
        isError:true,
      },
      "Last Name": {
        index:2,
        isError:true,
      },
      "Email": {
        index:3,
        isError:true,
      },
      "Address Line 1": {
        index:4,
        isError:true,
      },
      "City": {
        index:5,
        isError:true,
      },
      "State": {
        index:6,
        isError:true,
      },
      "Postal Code": {
        index:7,
        isError:true,
      },
      // "Country": {
      //   index:8,
      //   isError:true,
      //   needFocus:false,
      // },
      "Gender": {
        index:9,
        isError:false,
      },
      "Month": {
        index:10,
        isError:false,
      },
      "Day": {
        index:11,
        isError:false,
      },
      "Year": {
        index:12,
        isError:false,
      },
      "Phone Number": {
        index:13,
        isError:true,
      },
      "Payment Information": {
        index:14,
        isError:true,
      },
      "Provider Selection":{
        index:15,
        isError:false,
      },
      needFocus:"",
    },
    providerSetting: {
      shopAccessAutoApprove: false,
      isGuestCheckout: false,
      showDoctorSelector: false,
    },
    country_options: [
      {
        sellerOptionId: 999,
        description: "US",
      },
      {
        sellerOptionId: 998,
        description: "MX",
      },
    ],
    shipping_address_list:[],
  },
  actions: {
    changePayable({ commit }, isPayable) {
      commit("changePayable", isPayable);
    },
    updateStoreProfile({ commit }, data) {
      commit("updateStoreProfile", data);
    },
    updateTheme({ commit }, data) {
      commit("updateTheme", data);
    },
    updateThemeColor({ commit }, data) {
      commit("updateThemeColor", data);
    },
    updateShippingAddressList({ commit }, data) {
      commit("updateShippingAddressList", data);
    },
    updateCurrency({ commit }, data) {
      commit("updateCurrency", data);
    },
    updateProviderSetting({ commit }, data) {
      commit("updateProviderSetting", data);
    },
    updateCountryOptions({ commit }, data) {
      commit("updateCountryOptions", data);
    },
    updateDisplayFilter({ commit }, data) {
      commit("updateDisplayFilter", data);
    },
    updateFilterFixed({ commit }, data) {
      commit("updateFilterFixed", data);
    },
    updateLoading({ commit }, data) {
      commit("updateLoading", data);
    },
    updateProfileImg({ commit }, data) {
      commit("updateProfileImg", data);
    },
    updateFaqReadQuantity({ commit }, data) {
      commit("updateFaqReadQuantity", data);
    },
    updateFilter({ commit }, data) {
      commit("updateFilter", data);
    },
    updateSubcategoryFilter({ commit }, data) {
      commit("updateSubcategoryFilter", data);
    },
    resetFilter({ commit }, data) {
      commit("resetFilter", data);
    },
    updateSearchFilter({ commit }, data) {
      commit("updateSearchFilter", data);
    },
    updateProductHomeDisplayMode({ commit }, data) {
      commit("updateProductHomeDisplayMode", data);
    },
    updateErrorObject({ commit }, data) {
      commit("updateErrorObject", data);
    },
  },
  mutations: {
    changePayable(state, isPayable) {
      state.payable = isPayable;
    },
    updateStoreProfile(state, data) {
      state.storeProfile = data;
    },
    updateTheme(state, data) {
      state.theme = data;
    },
    updateThemeColor(state, data) {
      state.themeColorPalette = data;
    },
    updateShippingAddressList(state, data) {
      state.shipping_address_list = data;
    },
    updateCurrency(state, data) {
      state.currencyProperty = data;
    },
    updateProviderSetting(state, data) {
      state.providerSetting = data;
    },
    updateCountryOptions(state, data) {
      state.country_options = data;
    },
    updateErrorObject(state, data) {
      state.errorObject = data;
    },
    updateDisplayFilter(state, data) {
      state.displayFilter = data;
    },
    updateFilterFixed(state, data) {
      state.filterFixed = data;
    },
    updateLoading(state, data) {
      state.global_loading = data;
    },
    updateProfileImg(state, data) {
      state.profile_img = data;
    },
    updateFaqReadQuantity(state, data) {
      state.faqReadQuantity = data;
    },
    updateSubcategoryFilter(state, data) {
      state.subcategoryFilter = data
    },
    updateFilter(state, data) {
      Vue.set(state.global_filter, data.key, data.value);
      Vue.set(state.global_filter, "sellerId", util.getStoreId());
    },
    resetFilter(state, data) {
      state.global_filter = {
        price: [0, 300],
        productCategories: [],
        productSubcategories: [],
        productName: "",
        sellerId: -1,
        onSale: null,
        featured: null,
        autoShip: null,
        popular: null,
        atHome: null,
        sortBy: null,
        sortDir: "descend",
        pageNum: 0,
        pageSize: 30,
        suspend: false,
        draft: false,
        expired: false,
        deleted: false,
      };
    },
  },
});
