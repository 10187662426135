const network = {
  get(mythis, url, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .get(url, {
        emulateJSON: true,
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.zShopToken).access_token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
  getWithToken(mythis, url, token, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .get(url, {
        emulateJSON: true,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
  post(mythis, url, data, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .post(url, data, {
        //emulateJSON: true,
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.zShopToken).access_token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          callback({ error: response });
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
  postWithToken(mythis, url, data, token, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .post(url, data, {
        //emulateJSON: true,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
  put(mythis, url, data, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .put(url, data, {
        //emulateJSON: true,
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.zShopToken).access_token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
  delete(mythis, url, data, callback, errorCallback, finalCallBack = null) {
    mythis.$http
      .delete(url, {
        body: data,
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.zShopToken).access_token,
        },
      })
      .then(
        (response) => {
          callback(response);
        },
        (response) => {
          // error callback
          if (response.status == 401) {
            localStorage.removeItem("zShopToken");
            mythis.$router.replace("/signin");
            return;
          }
          errorCallback ? errorCallback(response) : null;
        }
      )
      .finally(() => {
        finalCallBack && finalCallBack();
      });
  },
};
export default network;
