//import Vue from 'vue'

// import VueResource from 'vue-resource'
// Vue.use(VueResource);

// import Router from 'vue-router'
// Vue.use(Router)

// import Mint from 'mint-ui';
// Vue.use(Mint);

// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload)

// import Vuex from "vuex";
// Vue.use(Vuex);

// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

// import 'viewerjs/dist/viewer.css'
// import Viewer from 'v-viewer'
// Vue.use(Viewer)

// Vue.use(BootstrapVue)

import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
Vue.use(VueEasytable);

Vue.config.productionTip = false
Vue.config.devtools = true

import router from './router'
import store from "./vuex/storage.js";
import App from './App.vue'

import VaImgPreviewer from "./ui/VaImgPreviewer";
Vue.use(VaImgPreviewer);

import * as Sentry from "@sentry/vue";
Sentry.init({
  Vue,
  dsn: "https://c2a27c500cea3b1d47d85fdf2750d309@o1113004.ingest.sentry.io/4505721194283008",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/wellnessproz.com\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')

